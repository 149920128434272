import type { LanguageCode } from "src/utils/language";
export type SojernOptions = {
  origin?: SojernPlace;
  destination?: SojernPlace;
  currencyCode: string;
  languageCode: LanguageCode;
  pageType: SojernPageType;
  uid: string;
  path: string;
};

export type SojernPlace = {
  shortName?: string;
  countryCode?: string;
  region?: string;
  nearestAirportCode?: string;
};

export type SojernPageType =
  | "TripLanding"
  | "AirportLanding"
  | "BusSearch"
  | "TrainSearch"
  | "FerrySearch"
  | "FlightSearch"
  | "TripSearch"
  | "TicketSearch"
  | "HomePage";

export function buildSojernUrl(values: SojernOptions): string {
  const builder = new URL(
    "https://static.sojern.com/cip/p/j78NerKP1syLlJXS.html",
  );

  // c1 and c2 are the origin and destination city. But we seem
  // to use the shortName here as our best approximation of the city.
  // s1 and s2 are the origin and destination state or regions.
  // n1 and n2 are the origin and destination country codes.
  // a1 and a2 are the origin and destination airport codes.

  if (values.origin) {
    if (values.origin.shortName) {
      builder.searchParams.set("c1", values.origin.shortName);
    }
    if (values.origin.region) {
      builder.searchParams.set("s1", values.origin.region);
    }
    if (values.origin.countryCode) {
      builder.searchParams.set("n1", values.origin.countryCode);
    }
    if (values.origin.nearestAirportCode) {
      builder.searchParams.set("a1", values.origin.nearestAirportCode);
    }
  }

  if (values.destination) {
    if (values.destination.shortName) {
      builder.searchParams.set("c2", values.destination.shortName);
    }
    if (values.destination.region) {
      builder.searchParams.set("s2", values.destination.region);
    }
    if (values.destination.countryCode) {
      builder.searchParams.set("n2", values.destination.countryCode);
    }
    if (values.destination.nearestAirportCode) {
      builder.searchParams.set("a2", values.destination.nearestAirportCode);
    }
  }

  builder.searchParams.set("l", values.languageCode);
  builder.searchParams.set("cur", values.currencyCode);
  builder.searchParams.set("ccid", values.uid);
  // p is the unique description of the page the interest data is being collected from.
  builder.searchParams.set("p", values.pageType);
  // The path (e.g. /Nearby-Airports/Boston) is used to identify
  // the page the interest data is being collected from.
  builder.searchParams.set("pname", values.path);

  return builder.href;
}
