import { buildSojernUrl } from "./buildSojernUrl";
import type { SojernPlace, SojernPageType } from "./buildSojernUrl";
import { sendEvent } from "src/analytics/sendEvent";
export interface Input {
  origin?: SojernPlace;
  destination?: SojernPlace;
  pageType: SojernPageType;
}
static const SOJERN_CONSENT_PURPOSES_REQUIRED = [1, 3, 5];
static const SOJERN_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];

<let/loadCount=1 />
<let/triggerCount=1 />

<if-consent-given
  consentPurposesRequired=SOJERN_CONSENT_PURPOSES_REQUIRED
  legitimateInterestPurposesRequired=SOJERN_LEGITIMATE_INTEREST_PURPOSES_REQUIRED
>
  <lifecycle
    onMount() {
      sendEvent({
        category: "InterestData",
        action: "TriggerIFrame",
        label: `Sojern:${triggerCount}`,
        isNonInteraction: true,
      });
      triggerCount += 1;
    }
  />

  <const/url=buildSojernUrl({
    origin: input.origin,
    destination: input.destination,
    path: $global.url?.pathname ?? window.location.pathname,
    pageType: input.pageType,
    uid: $global.r2rUserContext.uid,
    languageCode: $global.languageCode,
    currencyCode: $global.r2rUserContext.currencyCode,
  }) />

  <iframe
    style="display:none"
    width="1"
    height="1"
    sandbox="allow-scripts"
    src=url
    onLoad() {
      sendEvent({
        category: "InterestData",
        action: "LoadIFrame",
        label: `Sojern:${loadCount}`,
        isNonInteraction: true,
      });
      loadCount += 1;
    }
  ></iframe>
</if-consent-given>
