import { sendEvent } from "src/analytics/sendEvent";
static type TravelAudienceGlobalVariable = ([string, string] | [string])[];
static type TravelAudiencePageType = "Homepage" | "ProductTopLevelPage";
export interface Input {
  pageType: TravelAudiencePageType;
  mobileAdId?: string;
  mobileAdType?: string;
}
static declare global {
  interface Window {
    _ttq: TravelAudienceGlobalVariable; // Travel Audience query data
  }
}
/*
  Level 1 denotes a Homepage request.
  Level 2 denotes Nearby Airports & A2B landing page requsts.
*/
static const TRAVEL_AUDIENCE_HOMEPAGE_REQUEST = "1";
static const TRAVEL_AUDIENCE_LANDING_PAGE_REQUEST = "2";
static const TRAVEL_AUDIENCE_PRODUCT_HOMEPAGE = "5";
static const TRAVEL_AUDIENCE_PRODUCT_LANDING_PAGE = "18";
static const TRAVEL_AUDIENCE_CONSENT_PURPOSES_REQUIRED = [1, 3, 5];
static {
  const TRAVEL_AUDIENCE_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];
}

<if-consent-given
  consentPurposesRequired=TRAVEL_AUDIENCE_CONSENT_PURPOSES_REQUIRED
  legitimateInterestPurposesRequired=TRAVEL_AUDIENCE_LEGITIMATE_INTEREST_PURPOSES_REQUIRED
>
  <const/uidCookie=($global.r2rUserContext.uid) />
  <const/product=(
    input.pageType === "Homepage"
      ? TRAVEL_AUDIENCE_PRODUCT_HOMEPAGE
      : TRAVEL_AUDIENCE_PRODUCT_LANDING_PAGE
  ) />
  <const/level=(
    input.pageType === "Homepage"
      ? TRAVEL_AUDIENCE_HOMEPAGE_REQUEST
      : TRAVEL_AUDIENCE_LANDING_PAGE_REQUEST
  ) />
  <const/language=$global.languageCode />
  <const/mobileAdId=input.mobileAdId />
  <const/mobileAdType=input.mobileAdType />
  <!-- Setup global variable data for Travel Audience. -->
  <lifecycle
    onMount() {
      window._ttq = window._ttq || [];
      window._ttq.push(["_setUser", uidCookie]);
      window._ttq.push(["_setAccount", "RTR"]);
      window._ttq.push(["_setDataSource", "dp"]);
      window._ttq.push(["_setProduct", product]);
      window._ttq.push(["_setLevel", level]);
      window._ttq.push(["_setLanguage", language]);
      if (mobileAdId !== undefined) {
        window._ttq.push(["_setMai", mobileAdId]);
      }
      if (mobileAdType !== undefined) {
        window._ttq.push(["_setMat", mobileAdType]);
      }
      window._ttq.push(["_track"]);
    }
  />

  <script
    type="text/javascript"
    onLoad() {
      sendEvent({
        category: "InterestData",
        action: "LoadIFrame", // Keeping consistent with LP labels
        label: `TravelAudience:${level}`,
        isNonInteraction: true,
      });
    }
    src="https://ads.travelaudience.com/js/ta.js"
  ></script>
</if-consent-given>
